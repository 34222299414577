<template>
      <div class="relative-pos grow-custom-1 flex-box flexcol ocy-white-8">
          <!--headerblok-->
          <div class="relative-pos w-100-ps-min ocy-white-8 shadow">
              <div class="flex-box flexcol w-100 padding-custom-05">
                  <div class="flex-box flexrow ai-center gap-05">
                      <div class="flex-box flexrow ai-center jc-start">
                        <img :src="myLogo" class="ocy-img-08-no-hover h-50-px" alt="logo">
                      </div>
                      <div class="grow-custom-1">&nbsp;
                      </div>
                  </div>
              </div>
          </div>
          <!--endHeaderblok-->

          <!--bodyBlok-->
          <div class="grow-custom-1 relative-pos w-100-ps-min flexnone-box flexrow jc-center padding-left-4 padding-right-4 padding-top-1 padding-bottom-1 ocy-white-3">
              <div class="relative-pos flex-box flexcol jc-start w-100">
                  <!--
                  <div class="w-100-ps flex-box flexcol ai-center jc-center padding-custom-1">
                        <img :src="myIconLogo" class="ocy-img-08-no-hover h-75-px" alt="icon-logo">
                        <span class="text-dark text-center font-bold font1050rem font-uppercase letterspace25">
                            {{ title01 }}
                        </span>
                        <span class="text-secondary text-center font10rem letterspace15">
                            {{ title02 }}
                        </span>
                  </div>  
                  -->
                  <div class="flex-box flexcol" style="padding-top: 0.5rem"></div>
                  <div class="relative-pos w-100-ps flex-box flexcol jc-center padding-custom-05">
                    <div class="relative-pos w-100-ps flex-box flexcol jc-center padding-custom-05 margin-bottom-2 rounded-custom-10 shadow bg-white">
                        <img :src="vacancy02" class="ocy-img-08-no-hover rounded-custom-10" alt="vacancy">
                        <div class="flex-box flexrow jc-end padding-top-1 padding-bottom-1">
                            <a :href="'mailto:'+mailprogrammermicrocontroller" target="_blank">
                            <div class="margin-bottom-05 padding-left-1 padding-right-1 padding-top-05 padding-bottom-05 flex-box flexrow rounded-custom-10 bg-primary shadow">
                                <div class="flex-box flexrow ai-center jc-center font1050rem font-bold">
                                    <i class="bi bi-envelope text-white"></i>
                                </div>
                                <div class="grow-custom-1 padding-custom-05 text-white font-bold font10rem letterspace25 flex-box flexrow ai-center jc-start">
                                    Kirim Email
                                </div>
                            </div>
                            </a>
                        </div>
                    </div>
                    <div class="relative-pos w-100-ps flex-box flexcol jc-center padding-custom-05 margin-bottom-2 rounded-custom-10 shadow bg-white">
                        <img :src="vacancy01" class="ocy-img-08-no-hover rounded-custom-10" alt="vacancy">
                        <!--
                        <div class="flex-box flexrow jc-end padding-top-1 padding-bottom-1">
                            <a :href="'mailto:'+mailmarketing" target="_blank">
                            <div class="margin-bottom-05 padding-left-1 padding-right-1 padding-top-05 padding-bottom-05 flex-box flexrow rounded-custom-10 bg-primary shadow">
                                <div class="flex-box flexrow ai-center jc-center font1050rem font-bold">
                                    <i class="bi bi-envelope text-white"></i>
                                </div>
                                <div class="grow-custom-1 padding-custom-05 text-white font-bold font10rem letterspace25 flex-box flexrow ai-center jc-start">
                                    Kirim Email
                                </div>
                            </div>
                            </a>
                        </div>
                        -->
                    </div>
                  </div> 
                  <!-- 
                  <div class="flex-box flexcol padding-custom-1">&nbsp;
                  </div>
                  -->
              </div>
          </div>
          <div class="grow-custom-1 relative-pos w-100-ps-min noneflex-box flexrow jc-center padding-custom-1 ocy-white-3">
              <div class="relative-pos flex-box flexcol jc-start w-100">
                  <!--
                  <div class="w-100-ps flex-box flexcol ai-center jc-center padding-custom-1">
                        <img :src="myIconLogo" class="ocy-img-08-no-hover h-75-px" alt="icon-logo">
                        <span class="text-dark text-center font-bold font1050rem font-uppercase letterspace25">
                            {{ title01 }}
                        </span>
                        <span class="text-secondary text-center font10rem letterspace15">
                            {{ title02 }}
                        </span>
                  </div>  
                  -->
                  <div class="flex-box flexcol" style="padding-top: 0.5rem"></div>
                  <div class="relative-pos w-100-ps flex-box flexcol jc-center padding-custom-05">
                    <div class="relative-pos w-100-ps flex-box flexcol jc-center padding-custom-05 margin-bottom-2 rounded-custom-10 shadow bg-white">
                        <img :src="vacancy02" class="ocy-img-08-no-hover rounded-custom-10" alt="vacancy">
                        <div class="flex-box flexrow jc-end padding-top-1 padding-bottom-1">
                            <a :href="'mailto:'+mailprogrammermicrocontroller" target="_blank">
                            <div class="margin-bottom-05 padding-left-1 padding-right-1 padding-top-05 padding-bottom-05 flex-box flexrow">
                                <div class="flex-box flexrow ai-center jc-center font10rem font-bold">
                                    <i class="bi bi-envelope text-dark"></i>
                                </div>
                                <div class="grow-custom-1 padding-custom-05 text-dark font-bold font10rem letterspace25 flex-box flexrow ai-center jc-start">
                                    Kirim Email
                                </div>
                            </div>
                            </a>
                        </div>
                    </div>
                    <div class="relative-pos w-100-ps flex-box flexcol jc-center padding-custom-05 margin-bottom-2 rounded-custom-10 shadow bg-white">
                        <img :src="vacancy01" class="ocy-img-08-no-hover rounded-custom-10" alt="vacancy">
                        <!--
                        <div class="flex-box flexrow jc-end padding-top-1 padding-bottom-1">
                            <a :href="'mailto:'+mailmarketing" target="_blank">
                            <div class="margin-bottom-05 padding-left-1 padding-right-1 padding-top-05 padding-bottom-05 flex-box flexrow">
                                <div class="flex-box flexrow ai-center jc-center font10rem font-bold">
                                    <i class="bi bi-envelope text-dark"></i>
                                </div>
                                <div class="grow-custom-1 padding-custom-05 text-dark font-bold font10rem letterspace25 flex-box flexrow ai-center jc-start">
                                    Kirim Email
                                </div>
                            </div>
                            </a>
                        </div>
                        -->
                    </div>
                  </div>  
                  <!--
                  <div class="flex-box flexcol padding-custom-1">&nbsp;
                  </div>
                  -->
              </div>
          </div>
          <!--endBodyBlok-->

          <!--footerBlok-->
          <div class="relative-pos w-100-ps-min flex-box flexrow jc-center ocy-white-3">©{{ yearnow }} Fsm.Co.Id 
          </div>
          <!--endFooterBlok-->
      </div>
</template>

<script>

export default {
  name: 'App',
  components: 
  {
  },
  data() {
      return {
        myLogo : window.origin+'/img/FSM Delivering Right.png',
        myIconLogo : window.origin+'/img/logo01.png',
        myTxtLogo : window.origin+'/img/text_fsm.png',
        vacancy01 : window.origin+'/img/Rekruitment Programmer Marketing.fmg.jpg',
        vacancy02 : window.origin+'/img/Rekruitment Programmer Microcontroller.fmg.png',
        yearnow : '',
        title01 : 'We Are Hiring',
        title02 : 'Programmer Microcontroller & Marketing',
        mailprogrammermicrocontroller : 'careers@fsm.co.id?subject=Loker Programmer Microcontroller',
        mailmarketing : 'careers@fsm.co.id?subject=Loker Marketing',
      }
  },
  created() {
  },
  mounted() {
      this.currentYear();
  },
  computed: {
  },        
  methods : {
        currentYear() {
            let tglnow = new Date()
            this.yearnow = tglnow.getFullYear()
        }, 
        loadedOnce(){
            localStorage.removeItem('reloaded');
            if (localStorage.getItem('reloaded')) {
                localStorage.removeItem('reloaded');
            } else {
                localStorage.setItem('reloaded', '1');
                location.reload();
            }
        },
        currentDate() {
            let tglnow = new Date()
            let thn1 = tglnow.getFullYear()
            let bln1 = tglnow.getMonth()+1
            let tgl1 = tglnow.getDate()
            let bln2 = bln1
            let tgl2 = tgl1

            if (bln1 < 10)
            {
                bln2 = '0'+bln1
            }
            else
            {
                bln2 = bln1
            }
            
            if (tgl2 < 10)
            {
                tgl2 = '0'+tgl1
            }
            else
            {
                tgl2 = tgl1
            }
            
            let date = thn1+''+bln2+''+tgl2
            return date
        },      
        currentTime() {
            let timenow = new Date()
            let hour1 = timenow.getHours()
            let minute1 = timenow.getMinutes()
            let hour2 = hour1
            let minute2 = minute1

            if (hour1 < 10)
            {
                hour2 = '0'+hour1
            }
            else
            {
                hour2 = hour1
            }
            
            if (minute1 < 10)
            {
                minute2 = '0'+minute1
            }
            else
            {
                minute2 = minute1
            }
            
            let time = hour2+''+minute2
            return time
        },    
  }        

}
</script>